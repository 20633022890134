<template>
  <div class="discounts_header-content">
    <img
      class="bg-img"
      src="../../assets/images/enterprise/discounts_header/bg.png"
      alt=""
    />
    <div class="content" v-html="content"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      content: "",
    };
  },
  mounted() {
    this.getAnnouncement();
  },
  methods: {
    getAnnouncement() {
      this.$api.general.announcement({ type: "accumulation" }).then((res) => {
        this.content = res.data;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.discounts_header-content {
  position: relative;
  .bg-img {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    height: 38vw;
  }
  .content {
    font-size: 1vw;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #d1d1d1;
    width: 65%;
    margin: 0 auto;
    max-height: 23vw;
    overflow: auto;
    position: relative;
    top: 6vw;
    text-align: left;
  }
}
</style>
